<template>
  <div class="memberItem">
    <div class="memberItem_content">
      <div class="icon">
        <img :src="avatar || USER_IMAGE" />
      </div>
      <p v-if="displayName" v-html="displayName"></p>
      <p v-else>{{ name }}</p>
    </div>
  </div>
</template>

<script>
import { USER_IMAGE } from '@/config/config'

export default {
  props: {
    avatar: String,
    name: String,
    displayName: String
  },
  data() {
    return {
      USER_IMAGE
    }
  }
}
</script>

<style lang="scss" scoped>
.memberItem {
  background-color: white;
  padding: 0 20px;
  position: relative;
  font-size: 14px;
  color: $titleColor;

  &_content {
    display: flex;
    align-items: center;

    .icon {
      height: 30px;
      width: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &:not(:last-child) > div {
    border-bottom: solid 1px #cccccc;
  }
}
.memberItem_content {
  p {
    margin: 14px 0;
  }
}
</style>
