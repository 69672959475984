<template>
  <div class="departmentItem">
    <div class="departmentItem_content">
      <p>{{ name }}</p>
      <img
        src="/images/organization/icon-arrow.png"
        class="departmentItem_icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>

<style lang="scss" scoped>
.departmentItem {
  background-color: white;
  padding: 0 20px;
  position: relative;
  font-size: 14px;
  color: $titleColor;
  p {
    margin: 14px 0;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_icon {
    height: 15px;
    transform: rotate(-90deg);
  }

  &:not(:last-child) > div {
    border-bottom: solid 1px #cccccc;
  }
}

</style>
