<template>
  <div class="organization">
    <pageHead :is-show-page-head="true" :title="pageTitle">
      <div slot="left" class="organization_headLeft">
        <backIcon @click="handleBack"/>
        <div class="closeText" @click="handleClose">关闭</div>
      </div>
      <p class="confirmText" slot="right" @click="handleConfirm">确认</p>
    </pageHead>
    <van-search
        v-model="searchText"
        show-action
        shape="round"
        placeholder="请输入搜索成员名"
        @search="onSearch"
        @input='handleSearchInput'
    >
     <template #action>
      <div @click="onSearch">搜索</div>
    </template>
    </van-search>
    <!--    <navList :options="departmentLevelArr"/>-->
    <div v-show="showSearchResult" class="searchContent"  v-infinite-scroll="loadMore"
         infinite-scroll-disabled="busy">
      <memberItem v-for="item in searchResult " v-bind="item" :key="item.id" @click.native="handleSelectMember(item)"/>
      <no-more v-if="!listQueryParams.hasNextPage"/>
    </div>
    <div class="organization_content" v-if="!showSearchResult">
      <!-- <member-item :avatar="USER_IMAGE" name="超级管理员" @click.native="handleAttAdmin"/> -->
      <van-empty v-if="memberList.length===0&&departmentList.length===0" description="该部门无成员及子部门"/>
      <departmentItem v-for="item in departmentList " :key="item.id" v-bind="item"
                      @click.native="handleDepartmentClick(item)"/>
      <memberItem v-for="item in memberList" v-bind="item" :key="item.id" @click.native="handleSelectMember(item)"/>
    </div>
    <div class="organization_selectedUserList" v-if="selectMemberList.length>0 || attAdmin">
      <!-- <p v-if="attAdmin" class="userTag" @click="attAdmin = false">@超级管理员</p> -->
      <p class="userTag" v-for="item in selectMemberList" :key="item.id" @click="handleRemoveSelectedUser(item)">
        @{{ item.name }}
      </p>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import backIcon from "@/components/pageHead/backIcon";
import departmentItem from "./components/departmentItem";
import memberItem from "./components/memberItem";
import NoMore from '@/components/uitls/NoMore'
// import navList from "./components/navList";
import {getLocalDeptNew} from '@/api/dept'
import {getUserByNameNew} from '@/api/suser'
import {USER_IMAGE} from "@/config/config";

const TYPE_DEPARTMENT = 1
const TYPE_MEMBER = 2
export default {
  props: {
    fromRouteName: String,
    selectMembers: {
      type: String,
      default: ''
    },
    id: String,
    quoteId: String
  },
  components: {
    pageHead,
    departmentItem,
    memberItem,
    backIcon,
    NoMore
    // navList
  },
  computed: {
    // 页面标题
    pageTitle() {
      return this.currentDepartment.name || '极氪智能科技'
    },
    currentDepartment() {
      const [currentDepartment] = this.departmentLevelArr.slice(-1)
      return currentDepartment || {}
    },
    // 部门列表
    departmentList() {
      const {organizationData} = this
      return organizationData.filter(item => (item.type === TYPE_DEPARTMENT))
    },
    // 部门成员列表
    memberList() {
      const {organizationData} = this
      return organizationData.filter(item => (item.type === TYPE_MEMBER))
    }
  },
  watch: {
    currentDepartment: {
      // 开始侦听后立即调用一次
      immediate: true,
      deep: true,
      handler(currentDepartment) {
        console.log(currentDepartment)
        this.getOrganizationData(currentDepartment.id)
      }
    }
  },
  mounted() {
    if(this.$route.query.selectMembers) {
      this.selectMemberList = this.$route.query.selectMembers.split(',').filter(item => item).map(item => {
        const [id, name] = item.split(':')
        return {id, name}
      })
    }
  },
  data() {
    return {
      // 部门层级数组
      departmentLevelArr: [],
      // 搜索内容
      searchText: "",
      oldSearchText: "",
      // 组织机构数据
      organizationData: [],
      // 选中的用户列表
      selectMemberList: [],
      superAdminList: [],
      USER_IMAGE,
      attAdmin: false,
      searchResult: [],
      showSearchResult: false,
      listQueryParams: {
        pageNum: 1,
        pageSize: 15,
        hasNextPage: true,
      },
      busy: true
    }
  },
  methods: {
    /**
     * 返回事件
     */
    handleBack() {
      const {id, selectMembers, tag} = this.$route.query
      const {departmentLevelArr , fromRouteName, quoteId} = this
      if (departmentLevelArr.length > 0) {
        departmentLevelArr.pop()
        console.log('handleBack', this.currentDepartment)
        return
      }
      this.$router.replace({name: fromRouteName, query: {selectMembers: selectMembers, id: id, tag, quoteId}})
      // this.$router.back()
    },
    /**
     * 确认事件
     */
    handleConfirm() {
      const {id, tag} = this.$route.query
      const {fromRouteName: name, selectMemberList, quoteId} = this
      this.$router.replace({
        name,
        query: {selectMembers: selectMemberList.map(item => `${item.id}:${item.name}`).join(','), id: id, tag, quoteId}
      })
    },
    /**
     * 关闭当前页面返回
     */
    handleClose() {
      const {id, selectMembers, tag} = this.$route.query
      const {fromRouteName, quoteId} = this
      this.$router.replace({name: fromRouteName, query: {selectMembers: selectMembers, id: id, tag, quoteId}})
    },
    /**
     * 搜索事件
     */
    // handleSearch() {

    // },
    /**
     * 选中成员
     */
    handleSelectMember(member) {
      console.log(member, this.selectMemberList)
      if (this.selectMemberList.map(item => parseInt(item.id)).includes(parseInt(member.id))) {
        this.selectMemberList = this.selectMemberList.filter(item => (parseInt(item.id) !== parseInt(member.id)))
      } else {
        this.selectMemberList.push(member)
      }
    },
    /**
     * 移除选中用户
     * @param userId
     */
    handleRemoveSelectedUser({id: userId}) {
      this.selectMemberList = this.selectMemberList.filter(item => item.id !== userId)
    },
    /**
     * 组织机构被选中
     * @param currentDepartment
     */
    handleDepartmentClick(currentDepartment) {
      this.departmentLevelArr.push(currentDepartment)
    },
    /**
     * 获取组织机构数据
     * @param departmentId
     * @returns {Promise<void>}
     */
    async getOrganizationData(departmentId) {
      // this.organizationData
      const res = await getLocalDeptNew(departmentId) || []
      if (res.length > 0) {
        this.organizationData = res.map(item => {
          if (item.isUserInfo) {
            return {
              ...item,
              avatar: item.userBasicInfo.avatar,
              id: item.userBasicInfo.userId,
              name: item.userBasicInfo.username,
              superAdmin: 0,
              type: 2
            }
          } else {
            return {
              ...item,
              avatar: '',
              id: item.deptBasicInfo.id,
              name: item.deptBasicInfo.deptName,
              superAdmin: -1,
              type: 1
            }
          }
        })
      } else {
        this.organizationData = []
      }
    },
    onSearch() {
      if(this.oldSearchText === this.searchText) return;
      this.searchResult = []
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 15,
        hasNextPage: true,
      }
      if(this.searchText.trim()) {
        // this.loadList()
        this.busy = false
      }
    },
    loadMore() {
      console.log('loadmore')
      this.loadList()
    },
    async loadList() {
      let listQueryParams = this.listQueryParams;
      listQueryParams.username = this.searchText;
      this.oldSearchText = this.searchText;
      if (listQueryParams.hasNextPage) {
        const result = await getUserByNameNew(listQueryParams) || {}
        let {records} = result || []
        let res = records.map(item => {
          return {
            ...item,
            id: item.userId,
            name: item.username,
            superAdmin: 0,
            type: 2
          }
        })
        this.showSearchResult = true
        if (!res.length) return;

        this.searchResult = res
        listQueryParams.hasNextPage = !res.lastPage
        listQueryParams.pageNum = listQueryParams.pageNum + 1
      }
    },
    handleSearchInput() {
      if(!this.searchText.trim()) {
        this.showSearchResult = false
        this.searchResult = []
        this.listQueryParams = {
          pageNum: 1,
          pageSize: 10,
          hasNextPage: true,
        }
      }
      this.busy = true
    }
  }
}
</script>

<style lang="scss" scoped>
.organization {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_headLeft {
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 100%;

    .closeText {
      margin: 0;
      padding: 10px;
      color: $titleColor;
    }
  }

  .confirmText {
    font-size: 14px;
    color: $titleColor;
  }

  .searchContent {
    flex: 1;
    overflow-y: auto;
  }

  &_content {
    flex: 1;
    overflow-y: auto;
  }

  &_selectedUserList {
    background-color: white;
    padding: 20px;
    margin: -5px;
    display: flex;
    flex-wrap: wrap;

    .userTag {
      background-color: $mainColor;
      color: white;
      height: 20px;
      border-radius: 10px;
      padding: 0 10px;
      margin: 5px;
      font-size: 12px;
    }
  }

}
</style>
